import { render, staticRenderFns } from "./LDTE5870Port-2.vue?vue&type=template&id=93f21f5e&"
import script from "./LDTE5870Port-2.vue?vue&type=script&lang=js&"
export * from "./LDTE5870Port-2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports